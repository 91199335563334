import { GET_LOGO, GET_STATES, GET_STATE_SCHOOLS, LOGIN_USER, AUTO_LOGIN } from "../../Types/types";
import i18n from '../../../i18n';

let intialState = {
    states: [],
    stateSchools: [],
    classes: [{ label: 'Class 9', value: 9 }, { label: 'Class 10', value: 10 }]
}


export default function authReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_LOGO:
            let obj = {
                "type": "state",
                "_id": "653ca33f99672747c86b56e2",
                "name": "Karnataka",
                "logo": "https://elite-logos.s3.ap-south-1.amazonaws.com/NTTF_1630820183829.png",
                "images": [
                    "https://testservercertificates.s3.amazonaws.com/img1_1703831842139.jpeg",
                    "https://testservercertificates.s3.amazonaws.com/img2_1703831842139.jpeg",
                    "https://testservercertificates.s3.amazonaws.com/course2_1703831842140.png"
                ],
                "quotes": [
                    "Empowering every child to learn and succeed!",
                    "Free Learning Platform",
                    "Technical Skills Development"
                ],
            }
            stateCopy.logoDetails = action.payload;
            localStorage.setItem('logo', action.payload.logo);
            localStorage.setItem('theme', JSON.stringify(action.payload.colors || { sidebarBgColor: '#333269', btnColor: '#5855d6', textColor: '#fff' }));
            const root = document.documentElement;
            root.style.setProperty('--button-color', JSON.parse(localStorage.getItem('theme'))?.btnColor);
            root.style.setProperty('--sidebar-color', JSON.parse(localStorage.getItem('theme'))?.sidebarBgColor);
            // stateCopy.logoDetails = obj;
            return stateCopy;
        case LOGIN_USER:
            stateCopy.isAuthorized = true;
            localStorage.setItem('loginToken', action.payload.data.token);
            localStorage.setItem('loginEmail', action.payload.data.email);
            localStorage.setItem('name', action.payload.data.firstname);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload.data));
            localStorage.setItem('role', action.payload.data.role);
            localStorage.setItem('i18nextLng', action.payload.lang ? action.payload.lang : action.payload.data.language);
            action.payload.lang ? i18n.changeLanguage(action.payload.lang) : i18n.changeLanguage(action.payload.data.language);
            stateCopy.user = action.payload.data;
            // window.location.href = action.payload?.role.includes('admin') ? '/manage-content' : '/my-learning';
            window.location.reload();
            return stateCopy;
        case AUTO_LOGIN:
            stateCopy.isAuthorized = true;
            localStorage.setItem('loginToken', action.payload.data.token);
            localStorage.setItem('loginEmail', action.payload.data.email);
            localStorage.setItem('name', action.payload.data.firstname);
            localStorage.setItem('profileDetails', JSON.stringify(action.payload.data));
            localStorage.setItem('role', action.payload.data.role);
            localStorage.setItem('i18nextLng', action.payload?.data?.language);
            i18n.changeLanguage(action.payload?.data?.language);
            stateCopy.user = action.payload?.data;
            // window.location.href = action.payload?.role.includes('admin') ? '/manage-content' : '/my-learning';
            window.location.reload();
            return stateCopy;
        case GET_STATES:
            stateCopy.states = action.payload.map(el => [{ value: el._id, label: el.name }][0]);
            return stateCopy;
        case GET_STATE_SCHOOLS:
            stateCopy.stateSchools = action.payload.map(el => [{ value: el._id, label: el.name, affiliation_id: el.affiliation_id }][0]);
            return stateCopy;
        default:
            return stateCopy;
    }
}

