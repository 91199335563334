import { EMPTY_PROFILE, GET_PROFILE, UPDATE_LANGUAGE } from "../../Types/types";


let intialState = {
    profileDetails: null
}


export default function profileReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_PROFILE:
            stateCopy.profileDetails = action.payload;
            localStorage.setItem('class', action.payload.class);
            return stateCopy;
        case EMPTY_PROFILE:
            stateCopy.profileDetails = null;
            return stateCopy;
        case UPDATE_LANGUAGE:
            localStorage.setItem('i18nextLng', action.payload);
            return stateCopy;
        default:
            return stateCopy;
    }
}

